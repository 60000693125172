import React, { useEffect, useState } from 'react';
import logo from './assets/images/fullsharskylogo.svg';
import future from './assets/images/thefuture.svg';

function App() {
  const [futureOpacity, setFutureOpacity] = useState('opacity-0');

  useEffect(() => {
    const timer = setTimeout(() => setFutureOpacity('opacity-100'), 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-500">
      <img src={logo} alt="Logo" className="w-1/2 mb-5" />
      <img
        src={future}
        alt="The Future"
        className={`w-1/3 transition-opacity duration-1000 ${futureOpacity}`}
      />
    </div>
  );
}

export default App;
